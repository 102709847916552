import type CookieService from "@/services/cookies/CookieService";
import type GTMService from "@/services/tracking/gtm.service";
import type { ExponeaService } from "@/services/tracking/exponea.service";
import type { CustomerService } from "@/services/customer.service";
import { Audience } from "@/utilities";

interface Experiment {
  name: string;
  variants: string[];
  validFrom: string;
  validUntil: string;
}

export class ExperimentsService {
  protected prefix = "experiment";
  protected experiments: Experiment[] = [];
  protected cookieService: CookieService;
  protected gtmService: GTMService;
  protected exponeaService: ExponeaService;
  protected customerService: CustomerService;

  constructor(
    cookieService: CookieService,
    gtmService: GTMService,
    exponeaService: ExponeaService,
    customerService: CustomerService
  ) {
    this.experiments = [
      /* 
      ? Sample experiment.
      {
           name: "dummy-name",
           variants: ["variant-a", "variant-b"],
           validFrom: "YYYY-mm-dd",
           validUntil: "YYYY-mm-dd",
       }, */
      // {
      //   name: "call-bubble",
      //   variants: ["default", "displayed"],
      //   validFrom: "2024-08-28",
      //   validUntil: "2024-09-16",
      // },
      {
        name: "modular-exit-intent",
        variants: ["default", "displayed"],
        validFrom: "2024-11-05",
        validUntil: "2024-11-18",
      },
      {
        name: "modem-vs-no-modem",
        variants: ["default", "displayed"],
        validFrom: "2025-03-24",
        validUntil: "2025-04-14",
      },
    ];

    this.gtmService = gtmService;
    this.exponeaService = exponeaService;
    this.customerService = customerService;
    this.cookieService = cookieService;
  }

  isExperimentValid(experiment: Experiment): boolean {
    const currentTime = new Date().getTime();

    const validFrom = new Date(experiment.validFrom).getTime();
    const validUntil = new Date(experiment.validUntil).getTime();

    if (validFrom <= currentTime && validUntil >= currentTime) {
      return true;
    }

    return false;
  }

  async getVariant(experimentName: string, audience: Audience): Promise<string | null> {
    let customerServiceResult;
    if (audience != Audience.All) {
      customerServiceResult = await this.customerService.getIsCustomer();

      if (audience == Audience.Customers && customerServiceResult.isCustomer === false) {
        return null;
      }

      if (audience == Audience.NonCustomers && customerServiceResult.isCustomer === true) {
        return null;
      }
    }

    const cookieName = `${this.prefix}-${experimentName}`;
    const cookie = this.cookieService.getCookieOne(cookieName);
    const experiment = this.experiments.find((experiment) => experiment.name === experimentName);

    if (experiment === undefined) {
      return null; //! Experiment doesn't exist.
    }

    if (cookie == "" || cookie == null) {
      if (this.isExperimentValid(experiment)) {
        const variant = experiment.variants[Math.floor(Math.random() * experiment.variants.length)];

        this.cookieService.setCookie(cookieName, variant, null, experiment.validUntil);

        const eventPayload = {
          experiment_name: experimentName,
          experiment_variant: variant,
          experiment_valid_from: experiment.validFrom,
          experiment_valid_until: experiment.validUntil,
          is_customer: customerServiceResult?.isCustomer,
        };

        this.gtmService.track("experiment_started", eventPayload);
        this.exponeaService.track("experiment_started", eventPayload);

        return variant;
      }

      return null; //! Experiment is expired.
    }

    return cookie; //* Experiment found.
  }
}

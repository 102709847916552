import * as Sentry from "@sentry/vue";

interface DefaultProgramIds {
  id_client: number;
  id_nonclient: number;
  id_client_promo: number;
  id_nonclient_promo: number;
  order: number;
}

interface TariffData {
  tariffs: DefaultProgramIds[];
  promo_card_id: number;
  promo_from: string;
  promo_until: string;
  promo_label: string;
  promo_heading: string;
  promo_content: string;
  promo_content_nonclient: string;
  promo_content_iptv: string;
  sim_tariff_client_text: string;
  sim_tariff_nonclient_text: string;
  tab_iptv_text: string;
  tab_iptv_sim_text: string;
  tab_iptv_sim_only_text: string;
  cta_iptv_title: string;
  cta_iptv_text: string;
  cta_iptv_note: string;
  cta_iptv_label: string;
  error_text_required_email: string;
  error_text_valid_email: string;
}

export class SimTariffsService {
  protected url = "/wp-json/sim-tariffs/get";

  protected dataKey = "sim-tariffs.data";
  protected checkedAtKey = "sim-tariffs.checkedAt";
  protected checkedThisSessionKey = "sim-tariffs.checkedThisSession";

  protected textEncoder = new TextEncoder();
  protected textDecoder = new TextDecoder();

  protected activeRequests = new Set();
  protected requestPromises = new Map();
  protected requestCache = new Map();

  protected data: TariffData;
  protected simTariffsCheckedAt: string | null;
  protected checkedThisSession: string | null;

  constructor() {
    try {
      const sessionData = localStorage.getItem(this.dataKey);

      if (sessionData) {
        this.data = JSON.parse(new TextDecoder().decode(Uint8Array.from(atob(sessionData), (c) => c.charCodeAt(0))));
      }
    } catch (error) {
      console.error("Error occurred: ", error);
      Sentry.captureException(error);
    }
    this.simTariffsCheckedAt = localStorage.getItem(this.checkedAtKey);
    this.checkedThisSession = sessionStorage.getItem(this.checkedThisSessionKey);
  }

  public async getData(): Promise<TariffData> {
    if (
      !this.data ||
      !this.simTariffsCheckedAt ||
      !this.checkedThisSession ||
      Date.now() - Number(this.simTariffsCheckedAt) > 1000 * 60 * 60
    ) {
      try {
        await this.request();
      } catch (error) {
        Sentry.captureException(error);
      }
    }

    return this.data;
  }

  protected async request(): Promise<Response> {
    if (this.requestCache.has(this.url)) {
      return Promise.resolve(this.requestCache.get(this.url));
    }

    if (this.activeRequests.has(this.url)) {
      return this.requestPromises.get(this.url);
    }

    this.activeRequests.add(this.url);

    const requestPromise = fetch(this.url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const currentDate = Date.now().toString();

        this.data = data;
        this.simTariffsCheckedAt = currentDate;
        this.checkedThisSession = "true";

        localStorage.setItem(
          this.dataKey,
          btoa(
            new TextEncoder()
              .encode(JSON.stringify(this.data))
              .reduce((data, byte) => data + String.fromCharCode(byte), "")
          )
        );
        localStorage.setItem(this.checkedAtKey, this.simTariffsCheckedAt);
        sessionStorage.setItem(this.checkedThisSessionKey, this.checkedThisSession);

        this.requestCache.set(this.url, data);
        this.activeRequests.delete(this.url);
        this.requestPromises.delete(this.url);
        return data;
      })
      .catch((error: Error) => {
        console.error("Error occurred: ", error);
        Sentry.captureException(error);
        this.activeRequests.delete(this.url);
        this.requestPromises.delete(this.url);
        throw error;
      });

    this.requestPromises.set(this.url, requestPromise);

    return requestPromise;
  }
}
